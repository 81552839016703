<template>
  
            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{ events_data?.p1_score }}</h5>
                    <span>{{ $t('volleyball.stats.point') }}</span>
                    <h5>{{ events_data?.p2_score }}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_score+events_data.p2_score>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_score, events_data.p2_score)" max="100"></progress>
                </div>
            </div>

            <!-- set won -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_sets !== undefined && events_data.p2_sets !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_sets}}</h5>
                    <span>{{ $t('volleyball.stats.sets_won') }}</span>
                    <h5>{{events_data?.p2_sets}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_sets+events_data.p2_sets>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_sets, events_data.p2_sets)" max="100"></progress>
                </div>
            </div>

            <!-- first set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_first_set_points !== undefined && events_data.p2_first_set_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_first_set_points}}</h5>
                    <span>{{ $t('volleyball.stats.first_set_points') }}</span>
                    <h5>{{events_data?.p2_first_set_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_first_set_points+events_data.p2_first_set_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_first_set_points, events_data.p2_first_set_points)" max="100"></progress>
                </div>
            </div>

            <!-- second set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_second_set_points !== undefined && events_data.p2_second_set_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_second_set_points}}</h5>
                    <span>{{ $t('volleyball.stats.second_set_points') }}</span>
                    <h5>{{events_data?.p2_second_set_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_second_set_points+events_data.p1_second_set_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_second_set_points, events_data.p1_second_set_points)" max="100"></progress>
                </div>
            </div>

            <!-- third set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_third_set_points !== undefined && events_data.p2_third_set_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_third_set_points}}</h5>
                    <span>{{ $t('volleyball.stats.third_set_points') }}</span>
                    <h5>{{events_data?.p2_third_set_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_third_set_points+events_data.p2_third_set_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_third_set_points, events_data.p2_third_set_points)" max="100"></progress>
                </div>
            </div>

            <!-- fourth set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_fourth_set_points !== undefined && events_data.p2_fourth_set_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_fourth_set_points}}</h5>
                    <span>{{ $t('volleyball.stats.fourth_set_points') }}</span>
                    <h5>{{events_data?.p2_fourth_set_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_fourth_set_points+events_data.p2_fourth_set_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_fourth_set_points, events_data.p2_fourth_set_points)" max="100"></progress>
                </div>
            </div>

            <!-- fifth set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_fifth_set_points !== undefined && events_data.p2_fifth_set_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_fifth_set_points}}</h5>
                    <span>{{ $t('volleyball.stats.fifth_set_points') }}</span>
                    <h5>{{events_data?.p2_fifth_set_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_fifth_set_points+events_data.p2_fifth_set_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_fifth_set_points, events_data.p2_fifth_set_points)" max="100"></progress>
                </div>
            </div>

            <!-- <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data.p1_red_cards}}</h5>
                    <span>{{ $t('soccer.stats.red_cards') }}</span>
                    <h5>{{events_data.p2_red_cards}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_red_cards+events_data.p2_red_cards>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_red_cards, events_data.p2_red_cards)" max="100"></progress>
                </div>
            </div> -->
</template>

<script>
import svgs from '../../assets/svgs.json'
import { storeToRefs } from 'pinia'
import { useFixtureStore } from '../../stores/Fixture'
export default {
    name: "StatsView",
    data() {
        return {
            svg_images: svgs,
            events_data: storeToRefs(useFixtureStore()).getSelectedFixture,
        }
    },
    methods: {
        getProgress(p1_score, p2_score){
            const total = parseInt(p1_score) + parseInt(p2_score);
            if(!total) return 0;
            const percentage = (p1_score / total) * 100;
            return percentage
        }
    },
}
</script>

<style>

</style>