import { defineStore } from "pinia";

export const useSportsStore = defineStore("sports", {
	state: () => ({
		sport_settings: null,
	}),
	getters: {
		getSportSettings: (state) => {
			return (slug) => {
				if (state.sport_settings) {
					return state.sport_settings.find(sport => sport.slug === slug);
				}
				return null;
			}
		  }
	},
	actions: {
		setSportSettings(data) {
			this.sport_settings = data;
			this.setCustomButtonColors(data)
		},
		setCustomButtonColors(data) {
			data.forEach(sport => {
				document.documentElement.style.setProperty(`--${sport.slug}-button-text-color`, sport.button_text_color);
				document.documentElement.style.setProperty(`--${sport.slug}-button-background-color`, sport.button_background_color);
				document.documentElement.style.setProperty(`--${sport.slug}-button-active-color`, sport.button_active_color);
			});
		}
	},
});
