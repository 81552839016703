<template>
  
            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{ events_data?.p1_goals }}</h5>
                    <span>{{ $t('icehockey.stats.goals') }}</span>
                    <h5>{{ events_data?.p2_goals }}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_goals+events_data.p2_goals>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_goals, events_data.p2_goals)" max="100"></progress>
                </div>
            </div>

            <!-- set won -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_first_period_goals !== undefined && events_data.p2_first_period_goals !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_first_period_goals}}</h5>
                    <span>{{ $t('icehockey.stats.first_Period_goals') }}</span>
                    <h5>{{events_data?.p2_first_period_goals}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_first_period_goals+events_data.p2_first_period_goals>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_first_period_goals, events_data.p2_first_period_goals)" max="100"></progress>
                </div>
            </div>

            <!-- first set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_second_period_goals !== undefined && events_data.p2_second_period_goals !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_second_period_goals}}</h5>
                    <span>{{ $t('icehockey.stats.second_Period_goals') }}</span>
                    <h5>{{events_data?.p2_second_period_goals}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_second_period_goals+events_data.p2_second_period_goals>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_second_period_goals, events_data.p2_second_period_goals)" max="100"></progress>
                </div>
            </div>

            <!-- second set -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_third_period_goals !== undefined && events_data.p2_third_period_goals !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_third_period_goals}}</h5>
                    <span>{{ $t('icehockey.stats.third_Period_goals') }}</span>
                    <h5>{{events_data?.p2_third_period_goals}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_third_period_goals+events_data.p2_third_period_goals>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_third_period_goals, events_data.p2_third_period_goals)" max="100"></progress>
                </div>
            </div>

            <!-- overtime -->
            <div class="statistics-range-main" v-if="events_data && events_data.p1_power_play_overtimes !== undefined && events_data.p2_power_play_overtimes !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_power_play_overtimes}}</h5>
                    <span>{{ $t('icehockey.stats.power_play_overtimes') }}</span>
                    <h5>{{events_data?.p2_power_play_overtimes}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_power_play_overtimes+events_data.p2_power_play_overtimes>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_power_play_overtimes, events_data.p2_power_play_overtimes)" max="100"></progress>
                </div>
            </div>

            
            <!-- <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data.p1_red_cards}}</h5>
                    <span>{{ $t('soccer.stats.red_cards') }}</span>
                    <h5>{{events_data.p2_red_cards}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_red_cards+events_data.p2_red_cards>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_red_cards, events_data.p2_red_cards)" max="100"></progress>
                </div>
            </div> -->
</template>

<script>
import svgs from '../../assets/svgs.json'
import { storeToRefs } from 'pinia'
import { useFixtureStore } from '../../stores/Fixture'
export default {
    name: "StatsView",
    data() {
        return {
            svg_images: svgs,
            events_data: storeToRefs(useFixtureStore()).getSelectedFixture,
        }
    },
    methods: {
        getProgress(p1_score, p2_score){
            const total = parseInt(p1_score) + parseInt(p2_score);
            if(!total) return 0;
            const percentage = (p1_score / total) * 100;
            return percentage
        }
    },
}
</script>

<style>

</style>