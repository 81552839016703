/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import { useSportsStore } from "../stores/sports";
import { CommonData } from "@/stores/Common";
import axios from 'axios';
import SoccerMainVue from "../views/SoccerMain.vue";
import BasketBallMail from "../views/BasketBallMain.vue";
import TennisMain from "../views/TennisMain.vue";
import VolleyBallMain from "../views/VolleyBallMain.vue";
import TableTennisMain from "../views/TableTennisMain.vue";
import HandballMain from "../views/HandballMain.vue";
import IceHockeyMain from "../views/IceHockeyMain.vue";
import BaseballMain from "../views/BaseballMain.vue";

const router = createRouter({
  history: createWebHistory(),
  base: `/3d-live-animation`,
  routes: [
    // {
    //   path: "/3d-live-animation/:locale/:fixture_id",
    //   name: "Soccer",
    //   component: SoccerMainVue,
    // },
    {
      path: "/3d-live-animation/soccer/:locale/:fixture_id",
      name: "Soccer",
      component: SoccerMainVue,
    },
    {
      path: "/3d-live-animation/basketball/:locale/:fixture_id",
      name: "Basketball",
      component: BasketBallMail,
    },
    {
      path: "/3d-live-animation/tennis/:locale/:fixture_id",
      name: "Tennis",
      component: TennisMain,
    },
    {
      path: "/3d-live-animation/volleyball/:locale/:fixture_id",
      name: "Volleyball",
      component: VolleyBallMain,
    },
    {
      path: "/3d-live-animation/table_tennis/:locale/:fixture_id",
      name: "Tabletennis",
      component: TableTennisMain,
    },
    {
      path: "/3d-live-animation/handball/:locale/:fixture_id",
      name: "Handball",
      component: HandballMain,
    },
    {
      path: "/3d-live-animation/ice_hockey/:locale/:fixture_id",
      name: "Icehockey",
      component: IceHockeyMain,
    },
    {
      path: "/3d-live-animation/baseball/:locale/:fixture_id",
      name: "Baseball",
      component: BaseballMain,
    },

  ],
});


router.beforeEach((to, from, next) => {
	if (CommonData().isDebugMode) {
		next();
		return;
	}

	axios.get(process.env.VUE_APP_BO_VIRTUAL_COURT_URL + '/api/customer/profile', {
		headers: {
			'Authorization': 'Bearer ' + to.query.token
		}
	}).then(response => {
    useSportsStore().setSportSettings(response.data.data.sports);
		next();
	}).catch(error => {
		console.error('There was an error!', error);
	});
});

export default router;
